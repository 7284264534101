import React from 'react'
import { Card, Col, Offcanvas, Row, Table } from 'react-bootstrap'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { PuffLoader } from 'react-spinners';
import { AuthContext, HostContext } from '../../context';
import { notif_error } from '../../notify';
const line_bottom = (30 / 100) * window.innerWidth;

const Pengaduan_statistik = ({ page_title }) => {
  window.document.title = page_title
  const { HOST } = React.useContext(HostContext)
  const { STATE_AUTH } = React.useContext(AuthContext)
  const [loading, set_loading] = React.useState(false)
  const [data_tahunan, set_data_tahunan] = React.useState([])
  const [data_bulanan, set_data_bulanan] = React.useState([])
  const [data_tahun, set_data_tahun] = React.useState([])
  const [show, set_show] = React.useState(false)




  const ambilDataStatistik = React.useCallback(async (tahun = null) => {
    set_loading(true)
    try {
      let requ = "";
      if (!tahun) {
        requ = await fetch(`${HOST}/aduan/statistik`, { method: 'GET', headers: { "REST-TOKEN": STATE_AUTH.token } })
      } else {
        requ = await fetch(`${HOST}/aduan/statistik/bulanan/${tahun}`, { method: 'GET', headers: { "REST-TOKEN": STATE_AUTH.token } })
      }

      const resu = await requ.json()
      if (resu.success) {
        if (!tahun) {
          set_data_tahunan(resu.payload)
        } else {
          const defaultMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
          const defaultValue = {
            "total_menunggu": 0,
            "total_investigasi": 0,
            "total_ditolak_tidak_terbukti": 0,
            "total_terima_terbukti": 0
          }
          let _data_bulanan = [];
          let _tahun = "";
          defaultMonth.forEach((item) => {
            let items = resu.payload.find(val => defaultMonth[parseInt(val.bulan) - 1] === item)
            _tahun = resu.payload[0].tahun
            if (items) {
              _data_bulanan.push(
                {
                  ...defaultValue,
                  total_menunggu: items.total_menunggu,
                  total_investigasi: items.total_investigasi,
                  total_ditolak_tidak_terbukti: items.total_ditolak_tidak_terbukti,
                  total_terima_terbukti: items.total_terima_terbukti,
                  bulan: item,
                  tahun: items.tahun,
                  tersedia: true
                }
              )
            } else {
              _data_bulanan.push(
                {
                  ...defaultValue,
                  bulan: item,
                  tahun: _tahun,
                  tersedia: false
                }
              )
            }
          })
          set_data_bulanan(_data_bulanan)

        }
      }
      set_loading(false)
    } catch (error) {
      notif_error({ msg: error.message })
      set_loading(false)
    }
  }, [HOST, STATE_AUTH])

  React.useEffect(() => {
    ambilDataStatistik()
  }, [ambilDataStatistik])

  const handle_show = () => set_show(true)
  const handle_hide = () => set_show(false)

  const handleGetTahun = (tahun) => {
    ambilDataStatistik(tahun)
    set_data_tahun(tahun)
    handle_show()
    console.log(data_bulanan)
  }



  return (
    <main>
      <Row>
        <Col md={12} className='my-3'>
          <h1 className='p-0 m-0 title_module'>STATISTIK PENGADUAN</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #1c1942' }} />
        </Col>
      </Row>
      <Row>
        {
          !loading
            ?
            data_tahunan.length > 0
              ?
              <Col md={12}>
                <span className='mb-4'>STATISTIK LAPORAN TAHUNAN</span>
                <div className='table-responsive'>
                  <Table hover striped>
                    <thead>
                      <tr>
                        <th rowSpan={2} width="40%" style={{ verticalAlign: 'middle' }}>TAHUN</th>
                        <th colSpan={4} className="text-center">STATUS ADUAN</th>
                      </tr>
                      <tr>
                        <th>Menunggu/Belum Ditanggapi</th>
                        <th>Investigasi</th>
                        <th>Ditolak/Tidak Terbukti</th>
                        <th>Diterima/Terbukti</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data_tahunan.map((v, i) => {
                          return <tr key={i} onClick={() => handleGetTahun(v.tahun)} style={{ cursor: 'pointer' }}>
                            <td>{v.tahun}</td>
                            <td>{v.total_menunggu}</td>
                            <td>{v.total_investigasi}</td>
                            <td>{v.total_ditolak_tidak_terbukti}</td>
                            <td>{v.total_terima_terbukti}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              </Col>
              :
              <Col md={12}>
                <Card>
                  <Card.Body className='d-flex justify-content-center align-items-center'>
                    <h2>Belum ada data</h2>
                  </Card.Body>
                </Card>
              </Col>
            :
            <Col md={12}>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <PuffLoader size={48} />
                <span>Mohon tunggu...</span>
              </div>
            </Col>
        }

      </Row>
      <Row>
        <Col md={12}>
          <div>
            <Offcanvas show={show} onHide={handle_hide} placement={"bottom"} backdrop="static" style={{ height: '85%' }}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Statistik Bulanan {data_tahun}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className='table-responsive'>
                  <h1 className="mt-1">DATA TAHUN {data_tahun}</h1>
                  {
                    !loading
                      ?
                      data_tahunan.length > 0
                        ?
                        <Col md={12}>
                          <span className='mb-2'>STATISTIK LAPORAN BULANAN</span>
                          <Table hover striped bordered>
                            <thead>
                              <tr>
                                <th rowSpan={2} width="20%" style={{ verticalAlign: 'middle' }}>TAHUN</th>
                                <th rowSpan={2} width="20%" style={{ verticalAlign: 'middle' }}>BULAN</th>
                                <th colSpan={4} className="text-center">STATUS ADUAN</th>
                              </tr>
                              <tr>
                                <th>Menunggu/Belum Ditanggapi</th>
                                <th>Investigasi</th>
                                <th>Ditolak/Tidak Terbukti</th>
                                <th>Diterima/Terbukti</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data_bulanan.map((v, i) => {
                                  return <tr key={i}>
                                    <td>{v.tahun}</td>
                                    <td>{v.bulan} {v.tersedia ? <sup><FaCheckCircle className='text-success'/></sup> : <sup><FaTimesCircle className='text-danger'/></sup>}</td>
                                    <td>{v.total_menunggu}</td>
                                    <td>{v.total_investigasi}</td>
                                    <td>{v.total_ditolak_tidak_terbukti}</td>
                                    <td>{v.total_terima_terbukti}</td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </Table>
                        </Col>
                        :
                        <Col md={12}>
                          <Card>
                            <Card.Body className='d-flex justify-content-center align-items-center'>
                              <h2>Belum ada data</h2>
                            </Card.Body>
                          </Card>
                        </Col>
                      :
                      <Col md={12}>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <PuffLoader size={48} />
                          <span>Mohon tunggu...</span>
                        </div>
                      </Col>
                  }
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </Col>
      </Row>
    </main>
  )
}

export default Pengaduan_statistik