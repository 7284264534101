import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { FaCheckCircle, FaClock, FaSearch, FaTimesCircle } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners';
import { AuthContext, HostContext } from '../../context'
import { notif_error } from '../../notify';
import moment from 'moment/moment';

const line_bottom = (30 / 100) * window.innerWidth;
const Pengaduan_laporan = ({ page_title }) => {
  window.document.title = page_title
  const pindah = useNavigate();
  const { HOST } = React.useContext(HostContext)
  const { STATE_AUTH } = React.useContext(AuthContext)
  const [data, set_data] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const [tahun, set_tahun] = React.useState(null)
  const [list_tahun, set_list_tahun] = React.useState([])
  const [status, set_status] = React.useState('semua')

  const ambilDataMasuk = React.useCallback(async () => {
    set_loading(true)
    try {
      const requ = await fetch(`${HOST}/aduan/ambil/${status}/${tahun}`, { method: 'GET', headers: { 'REST-TOKEN': STATE_AUTH.token } })
      const resu = await requ.json()
      if (resu.success) {
        set_data(resu.payload)
        set_list_tahun(Array.from(new Set(resu.payload.map(val => val.created_at.split('-')[0]))))
        set_loading(false)
      } else {
        set_data([])
        set_loading(false)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      set_loading(false)
    }
  }, [STATE_AUTH, HOST, status, tahun])

  React.useEffect(() => {
    ambilDataMasuk()
  }, [ambilDataMasuk])

  if (!STATE_AUTH.is_login) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <Row>
        <Col md={12} className='my-3'>
          <h1 className='p-0 m-0 title_module'>DATA LAPORAN</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #1c1942' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className='my-3'>
          <Row className='d-flex align-items-center justify-content-center my-3'>
            <Col md={6}>
              <span className='mb-3'>DAFTAR SEMUA ADUAN MASUK</span>
            </Col>
            <Col md={3}>
              <label htmlFor="status">Status</label>
              <select className='form-control' id="status" onChange={e => set_status(e.target.value)}>
                <option value="" disabled selected>--Pilih--</option>
                <option value="semua">Semua</option>
                <option value="menunggu">Menunggu</option>
                <option value="terima_investigasi">Investigasi</option>
                <option value="tolak_aduan_tidak_terbukti">Ditolak/Tidak Terbukti</option>
                <option value="terima_aduan_terbukti">Diterima/Terbukti</option>
              </select>
            </Col>
            <Col md={3}>
              <label htmlFor="tahun">Tahun</label>
              <select className='form-control' id="tahun" onChange={e => set_tahun(e.target.value)}>
                <option value="" disabled selected>--Pilih--</option>
                <option value={null}>Semua</option>
                {
                  list_tahun.map((v,i) => {
                    return <option value={v} key={i}>{v}</option>
                  })
                }
              </select>
            </Col>

          </Row>
          {
            !loading
              ?
              data.length > 0
                ?
                <Table hover striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Uraian Aduan</th>
                      <th>Dilaporkan Pada</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((val, ind) => {
                        return <tr style={{ cursor: 'pointer' }} onClick={() => pindah('/admin/pengaduan/rincian', { state: { ...val, status_detail_laporan: true } })}>
                          <td>{ind + 1}</td>
                          <td>{val.uraian_pengaduan.length > 50 ? `${val.uraian_pengaduan.slice(0, 100)}...` : val.uraian_pengaduan}</td>
                          <td>{moment(val.created_at, 'YYYY-MM-DD HH:mm:ss').locale('id').format('D MMMM YYYY  HH:mm')}</td>
                          <td className='text-center'>
                            {(val.status === "tolak" || val.status === "tolak_aduan_tidak_terbukti") && (<FaTimesCircle className='text-danger' title='Aduan ditolak' />)}
                            {(val.status === "terima_aduan_terbukti" || val.status === "terima") && (<FaCheckCircle className='text-success' title='Aduan diterima' />)}
                            {(val.status === "terima_investigasi") && (<FaSearch title='Aduan dalam tahap investigasi' />)}
                            {(val.status === "menunggu") && (<FaClock className='text-warning' title='Aduan masuk' />)}
                          </td>
                        </tr>
                      })
                    }

                  </tbody>
                </Table>
                :
                <Card>
                  <Card.Body className='d-flex justify-content-center align-items-center'>
                    <h2>Belum ada data</h2>
                  </Card.Body>
                </Card>
              :
              <div className='d-flex flex-column align-items-center'>
                <PuffLoader size={42} />
                <span>Mohon tunggu...</span>
              </div>

          }

        </Col>
      </Row>
    </main>
  )
}

export default Pengaduan_laporan