import React from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { FaArrowCircleRight, FaEye, FaPaperPlane, FaTimesCircle } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners';
import { AuthContext, HostContext } from '../../context'
import { notif_error, notif_success } from '../../notify';
import moment from 'moment/moment';

const line_bottom = (30 / 100) * window.innerWidth;
const Pengaduan = ({page_title}) => {
  window.document.title = page_title
  const pindah = useNavigate();
  const { HOST } = React.useContext(HostContext)
  const { STATE_AUTH } = React.useContext(AuthContext)
  const [data, set_data] = React.useState([])
  const [show, set_show_modal] = React.useState(false)
  const [loading, set_loading] = React.useState(false)
  const [id_aduan, set_id_aduan] = React.useState(null)
  const alasan = React.useRef()
  const form = React.useRef()
  const ambilDataMasuk = React.useCallback(async () => {
    set_loading(true)
    try {
      const requ = await fetch(`${HOST}/aduan/masuk/menunggu`,
        {
          headers: { 'REST-TOKEN': STATE_AUTH.token }
        })
      const resu = await requ.json()
      if (resu.success) {
        set_data(resu.payload)
        set_loading(false)
      } else {
        set_loading(false)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      set_loading(false)
    }
  }, [STATE_AUTH, HOST])

  React.useEffect(() => {
    ambilDataMasuk()
  }, [ambilDataMasuk])

  const setShowModal = (id_aduan) => { set_show_modal(true); set_id_aduan(id_aduan) }
  const setHideModal = () => { set_show_modal(false); form.current.reset() }


  const handleSubmitAlasan = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("ID_ADUAN", id_aduan)
    data.append("ALASAN", alasan.current.value)
    data.append("STATUS_TOLAK", "TOLAK")
    try {
      const requ = await fetch(`${HOST}/aduan/alasan`, { method: 'POST', headers: { "REST-TOKEN": STATE_AUTH.token }, body: data })
      const resu = await requ.json()
      if (resu.success) {
        notif_success({ msg: resu.message })
        setHideModal()
        ambilDataMasuk()
      } else {
        notif_error({ msg: resu.message })
      }
    } catch (err) {
      notif_error({ msmg: err.message })
    }
  }

  const handleSubmitInvestigasi = async (e) => {
    set_loading(true)
    const data = new FormData()
    data.append("ID_ADUAN", e.id_aduan)
    data.append("STATUS_TERIMA", 'TERIMA')
    try {
      const requ = await fetch(`${HOST}/aduan/investigasi`, { method: 'POST', headers: { "REST-TOKEN": STATE_AUTH.token }, body: data })
      const resu = await requ.json()
      if (resu.success) {
        notif_success({ msg: resu.message })
        set_loading(false)
        pindah('/admin/pengaduan')
      } else {
        notif_error({ msg: resu.message })
        set_loading(false)
      }
    } catch (err) {
      notif_error({ msmg: err.message })
      set_loading(false)
    }
  }

  if (!STATE_AUTH.is_login) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <Row>
        <Col md={12} className='my-3'>
          <h1 className='p-0 m-0 title_module'>PENGADUAN MASUK</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #1c1942' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <span className='mb-3'>DAFTAR ADUAN</span>
          {
            !loading
              ?
              data.length > 0
                ?
                data.map((val, ind) => {
                  return <Card className='p-2 d-flex flex-row align-items-center justify-content-between flex-wrap my-1' key={ind}>
                    <div>
                      <div>
                        <span className='fw-bold'>{val.uraian_pengaduan.length > 100 ? `${val.uraian_pengaduan.slice(0, 100)}...` : val.uraian_pengaduan}</span>
                      </div>
                      <div>
                        <span className='text-muted' style={{ fontSize: '12px' }}>{val.nama_pelapor} | {moment(val.created_at, 'YYYY-MM-DD HH:mm:ss').locale('id').format('D MMMM YYYY  HH:mm')}  </span>
                      </div>
                    </div>
                    <div>
                      <button className='btn btn-sm btn-info mx-2' onClick={() => pindah('/admin/pengaduan/rincian', { state: val })}><FaEye /> Lihat</button>
                      <button className='btn btn-sm btn-danger ' onClick={() => setShowModal(val.id_aduan)}><FaTimesCircle /> Tolak</button>
                      <button className='btn btn-sm btn-success mx-2' onClick={() => handleSubmitInvestigasi(val.id_aduan)}><FaArrowCircleRight /> Lanjut investigasi</button>
                    </div>
                  </Card>
                })
                :
                <Card>
                  <Card.Body className='d-flex justify-content-center align-items-center'>
                    <h2>Tidak ada laporan masuk</h2>
                  </Card.Body>
                </Card>
              :
              <div className='d-flex flex-column align-items-center'>
                <PuffLoader size={42} />
                <span>Mohon tunggu...</span>
              </div>

          }

        </Col>
      </Row>
      <Row>
        <Modal
          show={show}
        >
          <Form onSubmit={handleSubmitAlasan} ref={form}>
            <Modal.Header>
              Alasan Penolakan
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Control as={"textarea"} placeholder="Tuliskan deskripsi penolakan disi" ref={alasan} minLength="5" required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='warning' onClick={setHideModal}><FaTimesCircle /> Batal</Button>
              <Button variant='primary' type='submit'><FaPaperPlane /> Kirim</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Row>
    </main>
  )
}

export default Pengaduan