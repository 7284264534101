import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { FaCommentAlt, FaPaperPlane, FaUserLock } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
const Beranda = ({ page_data, page_title }) => {
  const direct = useNavigate()
  const line_bottom = (30 / 100) * window.innerWidth;
  React.useEffect(() => {
    window.document.title = page_title
  }, [page_title])
  return (
    <main>
      <section style={{ backgroundColor: '#421c6b', width: '100%', height: 'auto' }} className='d-flex align-items-center'>
        <Container className='my-5'>
          <Row>
            <Col md={12}>
              <h1 className='text-white'>Whistle Blower System</h1>
              <p className='text-white' style={{fontSize:'14px'}}>
                Jika Anda mengetahui tindakan melanggar peraturan yang telah atau akan dilakukan oleh seseorang yang anda kenal sebagai pegawai Pemerintah Kota Kotamobagu?
                Silahkan melapor ke Inspektorat Kota Kotamobagu. Jika pengaduan anda memenuhi syarat/kriteria yang dapat ditangani Inspektorat Kota Kotamobagu, maka akan diproses lebih lanjut oleh petugas.<br />
              </p>
              <Button variant='info' className='d-flex align-items-center' onClick={() => direct('/pengaduan/buat')}><FaPaperPlane className='me-1' /> KIRIM PENGADUAN</Button>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className='mt-3'>
          <Col md={12}>
            <h2><FaCommentAlt size={30} /> Kriteria Pengaduan</h2>
            <hr className='p-0 m-0 mb-3' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #421c6b' }} />
            <div className='alert border'>
              <p style={{fontSize:'12px'}} className='p-0 m-0'>
                Untuk anda yang ingin melaporkan indikasi tindak penyimpangan, tapi merasa sungkan atau takut identitasnya terungkap, karena kebetulan anda kenal dengan pelakunya, misalnya atasan, teman sekerja, dan lain-lain, anda bisa menggunakan fasilitas ini. Anda bisa melaporkan indikasi tindak penyimpangan yang dilakukan oleh atasan anda kepada bagian Pengawasan Internal di tempat anda bekerja, tapi apakah ada jaminan bahwa identitas anda akan terjaga kerahasiaannya? Dengan menjadi whistleblower bagi Inspektorat Kota Kotamobagu, kerahasiaan identitas anda akan dijamin oleh Inspektorat Kota Kotamobagu. Anda bisa menjadi whistleblower bagi Inspektorat Kota Kotamobagu di mana saja. <br />Adapun kriteria pengaduan sebagai berikut:
              </p>
              <ul className='list-group list-group-numbered  list-group-flush' style={{fontSize:'12px'}}>
                <li className='list-group-item'>Menjelaskan siapa, melakukan apa, kapan, di mana, mengapa dan bagaimana.</li>
                <li className='list-group-item'>Dilengkapi dengan bukti permulaan (data, dokumen, gambar dan rekaman) yang mendukung/menjelaskan adanya tindak penyimpangan.</li>
                <li className='list-group-item'>Diharapkan dilengkapi dengan data sumber informasi untuk pendalaman.</li>
              </ul>
            </div>
          </Col>
          <Col md={12}>
            <h2><FaUserLock size={30} /> Kerahasiaan</h2>
            <hr className='p-0 m-0 mb-3' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #421c6b' }} />
            <div className='alert border'>
              <p style={{fontSize:'12px'}} className='p-0 m-0'>
                Sistem ini secara teknis menjaga anonimitas Anda. Agar lebih menjamin Kerahasiaan, perhatikan hal-hal yang berikut ini:
              </p>
              <ul className='list-group list-group-numbered p-0 m-0 list-group-flush' style={{fontSize:'12px'}}>
                <li className='list-group-item'>Tidak mengisi data pribadi atau informasi yang memungkinkan bagi orang lain untuk melakukan pelacakan siapa Anda, seperti nama Anda atau hubungan Anda dengan pelaku-pelaku.</li>
                <li className='list-group-item'>Hindari penggunaan Komputer kantor Anda jika Pengaduan yang akan Anda berikan melibatkan pihak-pihak di dalam kantor Anda.</li>
                <li className='list-group-item'>Diharapkan dilengkapi dengan data sumber informasi untuk pendalaman.</li>
              </ul>
              <p style={{fontSize:'12px'}}>Inspektorat Kota Kotamobagu akan merahasiakan informasi pribadi Anda sebagai whistleblower, Inspektorat Kota Kotamobagu hanya fokus pada kasus yang dilaporka</p>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Beranda