import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { FaUserLock } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { AuthContext, HostContext } from '../../context'
import { notif_error, notif_info } from '../../notify'

const Login = ({ page_title }) => {
  React.useEffect(() => { window.document.title = page_title }, [page_title])
  const screen_height = window.innerHeight - 150;
  const { HOST } = React.useContext(HostContext)
  const { DISPATCH_AUTH } = React.useContext(AuthContext)
  const direct = useNavigate()
  const nama_pengguna = React.useRef()
  const kata_sandi = React.useRef()
  const [loading, set_loading] = React.useState(false)
  const handleLogin = async (e) => {
    e.preventDefault()
    set_loading(true)
    const data = new FormData()
    data.append('NAMA_PENGGUNA', nama_pengguna.current.value)
    data.append('KATA_SANDI', kata_sandi.current.value)
    try {
      const requ = await fetch(`${HOST}/auth/masuk`, { method: 'POST', body: data })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          set_loading(false)
          DISPATCH_AUTH({ type: 'LOGIN', ...resu.payload })
          if (resu.payload.is_admin) {
            direct('/admin')
          } else {
            console.log(resu)
          }
        } else {
          notif_info({ msg: resu.message })
          set_loading(false)
        }

      } else {
        throw new Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: `Masalah ditemukan : ${err.message}` })
      set_loading(false)
    }
  }
  return (
    <main>
      <Row className='d-flex align-items-center justify-content-center' style={{ height: screen_height }}>
        <Col md={3} className={"my-3"}>
          <Card>
            <Form onSubmit={handleLogin}>
              <Card.Header className='d-flex justify-content-center align-items-center flex-column'>
                <FaUserLock size={48} />
                <span>Masuk Pengguna</span>
              </Card.Header>
              <Card.Body>
                <Form.Group className='mb-3'>
                  <Form.Label>Nama Pengguna</Form.Label>
                  <Form.Control type='text' required autoComplete='off' ref={nama_pengguna} />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Kata Sandi</Form.Label>
                  <Form.Control type='password' required autoComplete='off' ref={kata_sandi} />
                </Form.Group>
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end'>
                {
                  !loading
                    ?
                    <Button type='submit'>Masuk</Button>
                    :
                    <span className='my-2'>Mohon tunggu...</span>
                }

              </Card.Footer>
            </Form>
          </Card>
        </Col>
      </Row>
    </main>
  )
}

export default Login