import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners';
import { AuthContext, HostContext } from '../../context'
import { notif_error } from '../../notify';
import moment from 'moment/moment';

const line_bottom = (30 / 100) * window.innerWidth;
const Pengaduan_investigasi = ({ page_title }) => {
  window.document.title = page_title
  const pindah = useNavigate();
  const { HOST } = React.useContext(HostContext)
  const { STATE_AUTH } = React.useContext(AuthContext)
  const [data, set_data] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const ambilDataMasuk = React.useCallback(async () => {
    set_loading(true)
    try {
      const requ = await fetch(`${HOST}/aduan/masuk/terima_investigasi`,
        {
          headers: { 'REST-TOKEN': STATE_AUTH.token }
        })
      const resu = await requ.json()
      if (resu.success) {
        set_data(resu.payload)
        set_loading(false)
      } else {
        set_loading(false)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      set_loading(false)
    }
  }, [STATE_AUTH, HOST])

  React.useEffect(() => {
    ambilDataMasuk()
  }, [ambilDataMasuk])

  if (!STATE_AUTH.is_login) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <Row>
        <Col md={12} className='my-3'>
          <h1 className='p-0 m-0 title_module'>INVESTIGASI</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #1c1942' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <span className='mb-3'>DAFTAR DATA ADUAN UNTUK INVESTIGASI</span>
          {
            !loading
              ?
              data.length > 0
                ?
                data.map((val, ind) => {
                  return <Card className='p-2 d-flex flex-row align-items-center justify-content-between flex-wrap my-1' key={ind}>
                    <div>
                      <div>
                        <span className='fw-bold'>{val.uraian_pengaduan.length > 100 ? `${val.uraian_pengaduan.slice(0, 100)}...` : val.uraian_pengaduan}</span>
                      </div>
                      <div>
                        <span className='text-muted' style={{ fontSize: '12px' }}>{val.nama_pelapor} | {moment(val.created_at, 'YYYY-MM-DD HH:mm:ss').locale('id').format('D MMMM YYYY  HH:mm')}  </span>
                      </div>
                    </div>
                    <div>
                      <button className='btn btn-sm btn-info mx-2' onClick={() => pindah('/admin/pengaduan/rincian', { state: { ...val, status_investigasi: true } })}><FaEye /> Lihat</button>
                    </div>
                  </Card>
                })
                :
                <Card>
                  <Card.Body className='d-flex justify-content-center align-items-center'>
                    <h2>Tidak ada laporan masuk</h2>
                  </Card.Body>
                </Card>
              :
              <div className='d-flex flex-column align-items-center'>
                <PuffLoader size={42} />
                <span>Mohon tunggu...</span>
              </div>

          }

        </Col>
      </Row>
    </main>
  )
}

export default Pengaduan_investigasi