import React from 'react'
import { Container, Image, NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import { AuthContext } from '../../context';

const NavbarMenu = () => {
  let title = "";

  const { STATE_AUTH, DISPATCH_AUTH } = React.useContext(AuthContext)
  const [isOffcanvasOpen, setIsOffcanvasOpen] = React.useState(false);
  const isSmallScreen = window.matchMedia('(max-width: 576px)').matches;
  const isMediumScreen = window.matchMedia('(min-width: 576px) and (max-width: 991px)').matches;
  const isLargeScreen = window.matchMedia('(min-width: 992px)').matches;

  function closeOffcanvas() {
    setIsOffcanvasOpen(false);
  }

  if (isSmallScreen) {
    title = "WBS";
  } else if (isMediumScreen) {
    title = "WBS";
  } else if (isLargeScreen) {
    title = "WBS - Inspektorat Kotamobagu";
  }

  const handleKeluar = () => {
    closeOffcanvas()
    DISPATCH_AUTH({ type: 'LOGOUT' })
  }


  const menu_publik = (
    <>
      <Nav.Item><NavLink to="/" className={'nav-link'} onClick={closeOffcanvas}>Beranda</NavLink></Nav.Item>
      <NavDropdown
        title="Pengaduan"
        id={`offcanvasNavbarDropdown-expand-sm`}
      >
        <NavDropdown.Item><NavLink to="/pengaduan/buat" className={'nav-link'} onClick={closeOffcanvas}>Buat</NavLink></NavDropdown.Item>
        <NavDropdown.Item><NavLink to="/pengaduan/pantau" className={'nav-link'} onClick={closeOffcanvas}>Pantau</NavLink></NavDropdown.Item>
      </NavDropdown>
      <Nav.Item><NavLink to="/tentang" className={'nav-link'} onClick={closeOffcanvas}>Tentang</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/login" className={'nav-link'} onClick={closeOffcanvas}>Login</NavLink></Nav.Item>
    </>
  )

  const menu_admin = (
    <>
      <Nav.Item><NavLink to="/admin" className={'nav-link'} onClick={closeOffcanvas} end>Beranda</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/admin/pengaduan" className={'nav-link'} onClick={closeOffcanvas} end>Masuk</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/admin/pengaduan/investigasi" className={'nav-link'} onClick={closeOffcanvas} end>Investigasi</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/admin/pengaduan/laporan" className={'nav-link'} onClick={closeOffcanvas} end>Laporan</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/admin/pengaduan/statistik" className={'nav-link'} onClick={closeOffcanvas} end>Statistik</NavLink></Nav.Item>
      <Nav.Item><NavLink to="/" className={'nav-link'} onClick={handleKeluar}>Keluar</NavLink></Nav.Item>
    </>
  )


  return (
    <>

      <Navbar bg="light" expand={"sm"} className={'fixed-top'} style={{ borderBottom: '3px solid #421c6b' }}>
        <Container>
          <Navbar.Brand href="#"><Image src={Logo} width={30}></Image> {title}</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} onClick={() => setIsOffcanvasOpen(true)} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
            show={isOffcanvasOpen}
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {
                  STATE_AUTH.is_login
                    ?
                    STATE_AUTH.is_admin
                      ?
                      menu_admin
                      :
                      ''
                    :
                    menu_publik
                }
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
}

export default NavbarMenu