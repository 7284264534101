import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const _props = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
}
const notif_info = (param) => {
    return toast.info(param.msg, {
        ..._props
    });
}

const notif_success = (param) => {
    return toast.success(param.msg, {
        ..._props
    });
}

const notif_warning = (param) => {
    return toast.warning(param.msg, {
        ..._props
    });
}

const notif_error = (param) => {
    return toast.error(param.msg, {
        ..._props
    });
}



const NotifContent = () => {
    return <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export { NotifContent, notif_info, notif_success, notif_warning, notif_error }