import React from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment'
import { AuthContext, HostContext } from '../../context';
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaCheckCircle, FaPaperPlane, FaTimesCircle } from 'react-icons/fa';
import { notif_error, notif_success } from '../../notify';
const line_bottom = (30 / 100) * window.innerWidth;
const Pengaduan_rincian = () => {
  const pindah = useNavigate()
  const { HOST } = React.useContext(HostContext)
  const { STATE_AUTH } = React.useContext(AuthContext)
  const { state } = useLocation()
  const [show, set_show_modal] = React.useState(false)
  const [loading, set_loading] = React.useState(false)
  const alasan = React.useRef()
  const form = React.useRef()
  const setShowModal = () => set_show_modal(true)
  const setHideModal = () => { set_show_modal(false); form.current.reset() }

  const isInvestigasi = state?.status_investigasi;
  const isDetailLaporan = state?.status_detail_laporan;
  const LinkBack = isInvestigasi ? "/admin/pengaduan/investigasi" : isDetailLaporan ? '/admin/pengaduan/laporan' : "/admin/pengaduan"

  const handleSubmitAlasan = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("ID_ADUAN", state.id_aduan)
    data.append("ALASAN", alasan.current.value)
    data.append("STATUS_TOLAK", !isInvestigasi ? "TOLAK" : "TOLAK_TIDAK_TERBUKTI")
    try {
      const requ = await fetch(`${HOST}/aduan/alasan`, { method: 'POST', headers: { "REST-TOKEN": STATE_AUTH.token }, body: data })
      const resu = await requ.json()
      if (resu.success) {
        notif_success({ msg: resu.message })
        setHideModal()
        pindah(LinkBack)
      } else {
        notif_error({ msg: resu.message })
      }
    } catch (err) {
      notif_error({ msmg: err.message })
    }
  }

  const handleSubmitProses = async (e) => {
    set_loading(true)
    const data = new FormData()
    data.append("ID_ADUAN", state.id_aduan)
    data.append("STATUS_TERIMA", !isInvestigasi ? "TERIMA" : "TERIMA_ADUAN_TERBUKTI")
    try {
      const requ = await fetch(`${HOST}/aduan/investigasi`, { method: 'POST', headers: { "REST-TOKEN": STATE_AUTH.token }, body: data })
      const resu = await requ.json()
      if (resu.success) {
        notif_success({ msg: resu.message })
        set_loading(false)
        pindah(LinkBack)
      } else {
        notif_error({ msg: resu.message })
        set_loading(false)
      }
    } catch (err) {
      notif_error({ msmg: err.message })
      set_loading(false)
    }
  }


  return (
    <main>
      <Row>
        <Col md={12} className='my-3'>
          <h1 className='p-0 m-0 title_module'>RINCIAN ADUAN</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #1c1942' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className='p-2'>
            <h2 className='m-0 p-0 h5 border-bottom'>INFORMASI PELAPOR </h2>
            <div className="mt-2">
              <Row className='mb-3'>
                <Col md={6}>
                  <small>Nama Pelapor</small><br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.nama_pelapor}</span>
                </Col>
                <Col md={6}>
                  <small>NIK Pelapor</small> <br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.nik_pelapor}</span>
                </Col>
              </Row>
            </div>
            <h2 className='h5 mt-2 border-bottom'>INFORMASI TERLAPOR DIDUGA TELAH MELAKUKAN TINDAK PIDANA PENYIMPANGAN </h2>
            <Row className='mb-3'>
              <Col md={12}>
                <small>Uraian Pengaduan</small> <br />
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.uraian_pengaduan}</span>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6} >
                <div>
                  <small>Nama Terlapor</small> <br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.nama_terlapor}</span>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <small>Nip Terlapor</small> <br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.nip_terlapor}</span>
                </div>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <div>
                  <small>Lokasi Kejadian</small><br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{state.lokasi_kejadian}</span>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <small>Perkiraan Tanggal Kejadian</small> <br />
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{moment(state.tanggal_perkiraan_kejadian, 'YYYY-MM-DD').locale('id').format('D MMMM YYYY')}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div>
                  <span>File Bukti Pendukung</span> <br />
                  <a className='btn btn-link' href={`${HOST.replace("/index.php", "")}${state.file_pendukung}`} rel='noreferrer' target={"_blank"}>Download</a>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-5 d-flex justify-content-center align-items-center my-3">
          <div>
            <button className='btn btn-warning' onClick={() => pindah(LinkBack)}><FaArrowCircleLeft /> Kembali</button>

            {
              !isInvestigasi
                ?
                !isDetailLaporan
                  ?
                  <>
                    <button className='btn btn-danger mx-2' onClick={setShowModal}><FaTimesCircle /> Tolak Aduan</button>
                    {
                      !loading
                        ?
                        <button className='btn btn-success' onClick={handleSubmitProses}><FaArrowAltCircleRight /> Lanjut Investigasi</button>
                        :
                        'Mohon tunggu...'
                    }
                  </>
                  :
                  ''
                :
                <>
                  <button className='btn btn-danger mx-2' onClick={setShowModal}><FaTimesCircle /> Tolak</button>
                  {
                    !loading
                      ?
                      <button className='btn btn-success' onClick={handleSubmitProses}><FaCheckCircle /> Terima</button>
                      :
                      'Mohon tunggu...'
                  }
                </>

            }


          </div>
        </Col>
      </Row>
      <Row>
        <Modal
          show={show}
        >
          <Form onSubmit={handleSubmitAlasan} ref={form}>
            <Modal.Header>
              Alasan Penolakan
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Control as={"textarea"} placeholder="Tuliskan deskripsi penolakan disi" ref={alasan} minLength="5" required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='warning' onClick={setHideModal}><FaTimesCircle /> Batal</Button>
              <Button variant='primary' type='submit'><FaPaperPlane /> Kirim</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Row>
    </main>
  )
}

export default Pengaduan_rincian