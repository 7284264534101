import React from 'react'
import { Row, Col, Form, Alert, Button, Card, Table } from 'react-bootstrap'
import { FaCheckCircle, FaClock, FaInfoCircle, FaSearch, FaTimesCircle } from 'react-icons/fa';
import { HostContext } from '../../context';
import { notif_error, notif_info } from '../../notify';
import { PuffLoader } from 'react-spinners'

const Pengaduan_pantau = ({ page_data, page_title }) => {
  const { HOST } = React.useContext(HostContext)
  const [data, set_data] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const line_bottom = (30 / 100) * window.innerWidth;
  React.useEffect(() => { window.document.title = page_title }, [page_title])
  const form_cari = React.useRef()
  const nomor_tiket = React.useRef()
  const handleCari = async (e) => {
    e.preventDefault()
    set_loading(true)
    const data = new FormData()
    data.append('NOMOR_TIKET', nomor_tiket.current.value)
    try {
      const requ = await fetch(`${HOST}/aduan/pantau`, { method: 'POST', body: data })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          set_data(resu.payload)
          set_loading(false)
        } else {
          notif_info({ msg: resu.message })
          set_data([])
          set_loading(false)
        }
      }
    } catch (err) {
      notif_error({ msg: `Masalah ditemukan, ${err.message}` })
      set_loading(false)
    }
  }
  return (
    <main>
      <Row>
        <Col md={12} className='mt-3'>
          <h1 className='p-0 m-0 title_module'>PANTAU ADUAN</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #421c6b' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mb-3'>
          <Alert className='mt-3 d-flex align-items-center '>
            <FaInfoCircle size={20} className='me-1' /> Pantau aduan anda dengan menggunakan nomor tiket yang telah anda terima sebelumnya
          </Alert>
          <Form onSubmit={handleCari} ref={form_cari}>
            <Form.Group className='row d-flex align-items-end'>
              <Col md={8} className="mb-1">
                <Form.Label>Nomor Tiket Anda</Form.Label>
                <Form.Control type={"text"} ref={nomor_tiket} minLength={5} maxLength={5} required/>
              </Col>
              <Col md={4} className="mb-1">
                <Button variant='info' type='submit'>Cek Aduan</Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            !loading
              ?
              data.length > 0
                ?
                <Card>
                  <Card.Body>
                    <div className='table-responsive'>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Nomor Tiket</th>
                            <th>Status Aduan</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data[0].no_tiket}</td>
                            <td>
                              {
                                data[0].status === "menunggu" && (<Alert variant='warning' className='d-flex align-items-center'><FaClock size={24} className="mx-2" /> Dalam proses</Alert>)
                              }
                              {
                                data[0].status === "tolak" && (<Alert variant='danger' className='d-flex align-items-center'><FaTimesCircle size={24} className="mx-2" /> Ditolak</Alert>)
                              }
                              {
                                data[0].status === "tolak_aduan_tidak_terbukti" && (<Alert variant='danger' className='d-flex align-items-center'><FaTimesCircle size={24} className="mx-2" /> Ditolak</Alert>)
                              }
                              {
                                data[0].status === "terima_investigasi" && (<Alert variant='info' className='d-flex align-items-center'><FaCheckCircle size={24} className="mx-2" />Aduan diterima</Alert>)
                              }
                              {
                                data[0].status === "terima_aduan_terbukti" && (<Alert variant='success' className='d-flex align-items-center'><FaCheckCircle size={24} className="mx-2" />Aduan diterima</Alert>)
                              }
                            </td>
                            <td>
                              {
                                data[0].status === "menunggu" && (<Alert variant='warning'>Aduan anda dalam proses verifikasi</Alert>)
                              }
                              {
                                data[0].status === "tolak" && (<Alert variant='danger'>Aduan anda ditolak</Alert>)
                              }
                              {
                                data[0].status === "tolak_aduan_tidak_terbukti" && (<Alert variant='danger'>{data[0].alasan}</Alert>)
                              }
                              {
                                data[0].status === "terima_investigasi" && (<Alert variant='info'>Aduan anda dalam tahap investigasi</Alert>)
                              }
                              {
                                data[0].status === "terima_aduan_terbukti" && (<Alert variant='success'>Terima kasih atas aduan anda. Aduan yang anda sampaikan telah terbukti </Alert>)
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
                :
                ''
              :
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <PuffLoader size={48} />
                <span>Mohon tunggu..</span>
              </div>
          }
          {
            !loading
              ?
              data.length === 0 && (
                <Card style={{ height: '220%' }}>
                  <Card.Body className='d-flex justify-content-center flex-column align-items-center'>
                    <FaSearch size={43} />
                    <span>Data anda akan tampil disini</span>
                  </Card.Body>
                </Card>
              )
              :
              ''
          }

        </Col>
      </Row>
    </main>
  )
}

export default Pengaduan_pantau