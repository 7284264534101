import React from 'react'
import { Container } from 'react-bootstrap'
import Navbar from './layout/navbar_menu'
// import Footer from './layout/footer'
// list page of public
import BERANDA from './public/beranda'
import PENGADUAN from './public/pengaduan'
import PENGADUAN_PANTAU from './public/pengaduan_pantau'
import TENTANG from './public/tentang'
import LOGIN from './public/login'
import NOPAGE from './public/nopage'
// list page of admin
import ADMIN_BERANDA from './admin/beranda'
import ADMIN_PENGADUAN from './admin/pengaduan'
import ADMIN_PENGADUAN_RINCIAN from './admin/pengaduan_rincian'
import ADMIN_PENGADUAN_INVESTIGASI from './admin/pengaduan_investigasi'
import ADMIN_PENGADUAN_LAPORAN from './admin/pengaduan_laporan'
import ADMIN_PENGADUAN_STATISTIK from './admin/pengaduan_statistik'
const index = ({ page_access, page_name, page_data, page_title }) => {
  let halaman = null;

  switch (page_access) {
    case 'public':
      switch (page_name) {
        case 'beranda':
          halaman = <BERANDA page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan':
          halaman = <PENGADUAN page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan_pantau':
          halaman = <PENGADUAN_PANTAU page_data={page_data} page_title={page_title} />
          break;
        case 'tentang':
          halaman = <TENTANG page_data={page_data} page_title={page_title} />
          break;
        case 'login':
          halaman = <LOGIN page_data={page_data} page_title={page_title} />
          break;
        case 'nopage':
          halaman = <NOPAGE page_data={page_data} page_title={page_title} />
          break;
        default:
      }
      break;
    case 'admin':
      switch (page_name) {
        case 'beranda':
          halaman = <ADMIN_BERANDA page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan':
          halaman = <ADMIN_PENGADUAN page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan_rincian':
          halaman = <ADMIN_PENGADUAN_RINCIAN page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan_investigasi':
          halaman = <ADMIN_PENGADUAN_INVESTIGASI page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan_laporan':
          halaman = <ADMIN_PENGADUAN_LAPORAN page_data={page_data} page_title={page_title} />
          break;
        case 'pengaduan_statistik':
          halaman = <ADMIN_PENGADUAN_STATISTIK page_data={page_data} page_title={page_title} />
          break;
        default:
      }
      break;
    default:
  }

  return (
    <>
      <Navbar />
      {

        (page_access === 'public' && page_name === 'beranda')
          ||
          (page_access === 'admin' && page_name === 'beranda')

          ?

          <div style={{ marginTop: '56px' }}>{halaman}</div>
          :
          (<Container style={{ marginTop: '56px' }}>{halaman}</Container>)}
      {/* <Footer /> */}
    </>
  )
}

export default index