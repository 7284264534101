const ls = localStorage;
const ul = "_WBS_USER_LOGIN_";
const ud = "_WBS_USER_DATA_";

const ls_login = ls.getItem(ul)
const ls_usdat = ls.getItem(ud)
let prepare_data = {}
if (ls_login) {
      prepare_data = JSON.parse(ls_usdat)
} else {
      prepare_data = {
            id_pengguna: null,
            is_login: false,
            is_admin: false,
            is_aktif: false,
            nama_pengguna: null,
            nama_lengkap: null,
            token: null
      }
}

export const AuthInit = prepare_data

export const AuthReducers = (state, receive) => {
      const rec = receive;
      switch (rec.type) {
            case 'LOGIN':
                  const set_data = {
                        ...state,
                        id_pengguna: rec.id_pengguna,
                        is_login: true,
                        is_admin: rec.is_admin,
                        is_aktif: rec.is_aktif,
                        nama_pengguna: rec.nama_pengguna,
                        nama_lengkap: rec.nama_lengkap,
                        token: rec.token
                  }
                  ls.setItem(ul, set_data.is_login)
                  ls.setItem(ud, JSON.stringify(set_data))

                  return set_data

            case 'GANTI_TOKEN':
                  const update_data = { ...state, token: rec.token }
                  ls.setItem(ud, JSON.stringify(update_data))
                  return update_data

            case 'LOGOUT':
                  ls.removeItem(ul)
                  ls.removeItem(ud)
                  return AuthInit
            default:
                  return state
      }
}