import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { FaChartBar, FaCommentAlt, FaFileAlt, FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
const Beranda = ({ page_title }) => {
  const direct = useNavigate()
  React.useEffect(() => {
    window.document.title = page_title
  }, [page_title])
  const halaman = [
    {
      judul: 'Pengaduan Masuk',
      icon: <FaCommentAlt size={48} />,
      link: '/admin/pengaduan',
      keterangan: 'Modul pengaduan masuk'

    },
    {
      judul: 'Investigasi Pengaduan',
      icon: <FaSearch size={48} />,
      link: '/admin/pengaduan/investigasi',
      keterangan: 'Modul untuk melihat data hasil investigasi aduan masuk'
    },
    {
      judul: 'Laporan Pengaduan',
      icon: <FaFileAlt size={48} />,
      link: '/admin/pengaduan/laporan',
      keterangan: 'Modul untuk melihat laporan aduan masuk'
    },
    {
      judul: 'Statistik Pengaduan',
      icon: <FaChartBar size={48} />,
      link: '/admin/pengaduan/statistik',
      keterangan: 'Modul untuk melihat statistik pengaduan'
    }
  ];
  return (
    <main>
      <section style={{ backgroundColor: '#1c1942', width: '100%', height: 'auto' }} className='d-flex align-items-center'>
        <Container className='my-5'>
          <Row>
            <Col md={12}>
              <h1 className='text-white'>Selamat datang, Admin</h1>
              <p className='text-white' style={{ fontSize: '14px' }}>
                Anda saat ini sedang mengakses halaman beranda Whistleblowing
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <section style={{ backgroundColor: '#fff', height: 'auto' }} className='d-flex align-items-center mt-3'>
          <Container>
            <Row>
              {halaman.map((v, i) => {
                return <Col md={6} key={i} onClick={() => direct(v.link)} style={{ cursor: 'pointer' }}>
                  <Card className='my-2'>
                    <Card.Body className='d-flex align-items-center justify-content-between' style={{ height: '100px' }}>
                      <div>
                        <h2 className='h4'>{v.judul}</h2>
                        <p className='text-muted' style={{ fontSize: '12px' }}>{v.keterangan}</p>
                      </div>
                      {v.icon}
                    </Card.Body>
                  </Card>
                </Col>
              })}
            </Row>
          </Container>
        </section>
      </Container>
    </main>
  )
}

export default Beranda