import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthContext } from '../context'
import Pages from '../pages'

const Index = () => {
  const { STATE_AUTH } = React.useContext(AuthContext)
  const menu_admin = (
    <>
      <Route exact path='/admin' element={<Pages page_access={'admin'} page_name={'beranda'} page_data={null} page_title={'Beranda'} />}></Route>
      <Route exact path='/admin/pengaduan' element={<Pages page_access={'admin'} page_name={'pengaduan'} page_data={null} page_title={'Aduan Masuk'} />}></Route>
      <Route path='/admin/pengaduan/rincian' element={<Pages page_access={'admin'} page_name={'pengaduan_rincian'} page_data={null} page_title={'Rincian Data Aduan '} />}></Route>
      <Route path='/admin/pengaduan/investigasi' element={<Pages page_access={'admin'} page_name={'pengaduan_investigasi'} page_data={null} page_title={'Investigasi Laporan Pengaduan'} />}></Route>
      <Route path='/admin/pengaduan/laporan' element={<Pages page_access={'admin'} page_name={'pengaduan_laporan'} page_data={null} page_title={'Laporan Aduan Masuk'} />}></Route>
      <Route path='/admin/pengaduan/statistik' element={<Pages page_access={'admin'} page_name={'pengaduan_statistik'} page_data={null} page_title={'Statistik Aduan'} />}></Route>
    </>
  )

  const menu_publik = (
    <>
      <Route exact path='/' element={<Pages page_access={'public'} page_name={'beranda'} page_data={null} page_title={'Beranda'} />}></Route>
      <Route path='/pengaduan/buat' element={<Pages page_access={'public'} page_name={'pengaduan'} page_data={null} page_title={'Pengaduan'} />}></Route>
      <Route path='/pengaduan/pantau' element={<Pages page_access={'public'} page_name={'pengaduan_pantau'} page_data={null} page_title={'Pantau Pengaduan'} />}></Route>
      <Route path='/tentang' element={<Pages page_access={'public'} page_name={'tentang'} page_data={null} page_title={'Tentang WBS'} />}></Route>
      <Route path='/login' element={<Pages page_access={'public'} page_name={'login'} page_data={null} page_title={'Masuk WBS'} />}></Route>
    </>
  )
  return (
    <Routes>
    <Route exact path='/' element={<Pages page_access={'public'} page_name={'beranda'} page_data={null} page_title={'Beranda'} />}></Route>
      {
        STATE_AUTH.is_login
          ?
          STATE_AUTH.is_admin
            ?
            menu_admin
            :
            ''
          :
          menu_publik
      }
      <Route exact path='/*' element={<Pages page_access={'public'} page_name={'nopage'} page_data={null} page_title={'404'} />}></Route>
    </Routes>
  )
}

export default Index