import React from 'react'
import { Card, Row, Col, ListGroup } from 'react-bootstrap'

const Tentang = () => {
  const line_bottom = (30 / 100) * window.innerWidth;
  return (
    <main>
      <Row>
        <Col md={12} className='mt-3'>
          <h1 className='p-0 m-0 title_module'>TENTANG WBS</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #421c6b' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className={"mt-3"}>
          <Card>
            <Card.Body>
              <h2>Pengertian</h2>
              <p style={{ textAlign: 'justify', textAlignLast: 'justify' }}>
                Whistleblowing adalah bentuk tindakan yang dilakukan oleh seorang atau beber apa orang Pegawai di lingkungan Pemerintah Kota Kotamobagu melakukan pengaduan dugaan Tindak Penyimpangan dalam penyelenggaraan pemerintahan daerah.
                Tindak Penyimpangan adalah perbuatan atau tindakan yang bertentangan dengan peraturan perundang-undangan, kode etik, dan/atau good governance di lingkungan Pemerintah Kota Kotamobagu.
                WBS (https://wbs.kotamobagu.go.id) adalah aplikasi yang disediakan oleh Pemerintah Kota Kotamobagu untuk memproses pengaduan yang dapat dimanfaatkan oleh Whistleblower untuk melakukan Whistleblowing tempat dimana Whistleblower berada.
              </p>


              <h2>Kerahasiaan WBS</h2>
              <p style={{ textAlign: 'justify' }}>
                Sistem ini secara teknis menjaga anonimitas Anda. Agar lebih menjamin Kerahasiaan, perhatikan hal-hal yang berikut ini:
                <ListGroup>
                  <ListGroup.Item>Tidak mengisi data pribadi atau informasi yang memungkinkan bagi orang lain untuk melakukan pelacakan siapa Anda, seperti nama Anda atau hubungan Anda dengan pelaku-pelaku.</ListGroup.Item>
                  <ListGroup.Item>Hindari penggunaan Komputer kantor Anda jika Pengaduan yang akan Anda berikan melibatkan pihak-pihak di dalam kantor Anda.</ListGroup.Item>
                  <ListGroup.Item>Inspektorat Kota Kotamobagu akan merahasiakan informasi pribadi Anda sebagai whistleblower, Inspektorat Kota Kotamobagu hanya fokus pada kasus yang dilaporkan.</ListGroup.Item>
                </ListGroup>
              </p>


              <h2>Kriteria Pengaduan</h2>
              <p style={{ textAlign: 'justify' }}>
                Untuk anda yang ingin melaporkan indikasi tindak penyimpangan, tapi merasa sungkan atau takut identitasnya terungkap, karena kebetulan anda kenal dengan pelakunya, misalnya atasan, teman sekerja, dan lain-lain, anda bisa menggunakan fasilitas ini. Anda bisa melaporkan indikasi tindak penyimpangan yang dilakukan oleh atasan anda kepada bagian Pengawasan Internal di tempat anda bekerja, tapi apakah ada jaminan bahwa identitas anda akan terjaga kerahasiaannya? Dengan menjadi whistleblower bagi Inspektorat Kota Kotamobagu, kerahasiaan identitas anda akan dijamin oleh Inspektorat Kota Kotamobagu. Anda bisa menjadi whistleblower bagi Inspektorat Kota Kotamobagu di mana saja.
                adapun kriteria pengaduan sebagai berikut:
                <ListGroup>
                  <ListGroup.Item>Menjelaskan siapa, melakukan apa, kapan, di mana, mengapa dan bagaimana.</ListGroup.Item>
                  <ListGroup.Item>Dilengkapi dengan bukti permulaan (data, dokumen, gambar dan rekaman) yang mendukung/menjelaskan adanya tindak penyimpangan.</ListGroup.Item>
                  <ListGroup.Item>Diharapkan dilengkapi dengan data sumber informasi untuk pendalaman.</ListGroup.Item>
                </ListGroup>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </main>
  )
}

export default Tentang