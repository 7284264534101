import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const Nopage = ({page_title}) => {
  window.document.title = page_title
  return (
    <main>
      <Row>
        <Col md={12} className="mt-5">
          <Card>
            <Card.Body className='text-center'>
              Halaman tidak ditemukan
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>
  )
}

export default Nopage