import React from 'react'
import { Row, Col, Form, Alert, Button, Modal } from 'react-bootstrap'
import { FaInfoCircle, FaPaperPlane } from 'react-icons/fa';
import { PuffLoader } from 'react-spinners';
import { HostContext } from '../../context';
import { notif_error, notif_success } from '../../notify';
const Pengaduan = ({ page_data, page_title }) => {
  const { HOST } = React.useContext(HostContext)
  React.useEffect(() => {
    window.document.title = page_title
  }, [page_title])

  const [data, set_data] = React.useState('')
  const [modal_show, set_modal_show] = React.useState(false)
  const [loading, set_loading] = React.useState(false)

  const line_bottom = (30 / 100) * window.innerWidth;
  const form_aduan = React.useRef()
  const nama_pelapor = React.useRef()
  const nik_pelapor = React.useRef()
  const uraian_pengaduan = React.useRef()
  const nama_terlapor = React.useRef()
  const nip_terlapor = React.useRef()
  const lokasi_kejadian = React.useRef()
  const tanggal_perkiraan_kejadian = React.useRef()
  const file_pendukung = React.useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    set_loading(true)
    const data = new FormData()
    data.append('NAMA_PELAPOR', nama_pelapor.current.value);
    data.append('NIK_PELAPOR', nik_pelapor.current.value);
    data.append('URAIAN_PENGADUAN', uraian_pengaduan.current.value);
    data.append('NAMA_TERLAPOR', nama_terlapor.current.value);
    data.append('NIP_TERLAPOR', nip_terlapor.current.value);
    data.append('LOKASI_KEJADIAN', lokasi_kejadian.current.value);
    data.append('TANGGAL_PERKIRAAN_KEJADIAN', tanggal_perkiraan_kejadian.current.value);
    data.append('FILE_PENDUKUNG', file_pendukung.current.files[0]);
    try {
      const requ = await fetch(`${HOST}/aduan/buat`, { method: 'POST', body: data })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          form_aduan.current.reset()
          notif_success({ msg: resu.message })
          set_data(resu.payload)
          set_modal_show(true)
          set_loading(false)
        }
      } else {
        set_loading(false)
        throw Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      set_loading(false)
    }
  }

  const ModalTiket = (props) => {
    return (
      <Modal{...props} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Informasi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='info'>
            <span className='d-flex align-items-center'>
              <FaInfoCircle size={56} />
              <small className='ms-1 text-muted'>{data.info}</small>
            </span>
            <hr />
            <p className='p-0 m-0'>Nomor tiket anda adalah : <span className='fw-bold'>{data.tiket}</span></p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Keluar</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <main>
      <Row>
        <Col md={12} className='mt-3'>
          <h1 className='p-0 m-0 title_module'>BUAT PENGADUAN</h1>
          <hr className='p-0 m-0' width={line_bottom + 'px'} style={{ borderBottom: '3px solid #421c6b' }} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Alert className='mt-3 d-flex align-items-center '>
            <FaInfoCircle size={20} className='me-1' /> Sampaikan aduan anda melalui form dibawah
          </Alert>
          <Form onSubmit={handleSubmit} ref={form_aduan}>
            <h2 className='m-0 p-0 h5'>INFORMASI PELAPOR </h2>
            <Form.Group>
              <Row className='mb-3'>
                <Col md={6}>
                  <Form.Label>Nama Pelapor</Form.Label>
                  <Form.Control type='text' autoComplete='off' ref={nama_pelapor} minLength={3} />
                </Col>
                <Col md={6}>
                  <Form.Label>NIK Pelapor</Form.Label>
                  <Form.Control type='number' autoComplete='off' ref={nik_pelapor} minLength={17} />
                </Col>
              </Row>
            </Form.Group>
            <h2 className='h5 mt-2'>INFORMASI TERLAPOR DIDUGA TELAH MELAKUKAN TINDAK PIDANA PENYIMPANGAN </h2>
            <Form.Group className='mb-3'>
              <Form.Label>Uraian Pengaduan</Form.Label>
              <Form.Control as="textarea" rows={3} required minLength={10} ref={uraian_pengaduan} />
            </Form.Group>
            <Row className='mb-3'>
              <Col md={6} >
                <Form.Group>
                  <Form.Label>Nama Terlapor</Form.Label>
                  <Form.Control type='text' autoComplete='off' required ref={nama_terlapor} minLength={3} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Nip Terlapor</Form.Label>
                  <Form.Control type='number' autoComplete='off' ref={nip_terlapor} minLength={18} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Lokasi Kejadian</Form.Label>
                  <Form.Control type='text' autoComplete='off' ref={lokasi_kejadian} minLength={3} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Perkiraan Tanggal Kejadian</Form.Label>
                  <Form.Control type='date' ref={tanggal_perkiraan_kejadian} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>File Bukti Pendukung</Form.Label>
                  <Form.Control type='file' ref={file_pendukung} />
                  <small className='text-muted' style={{ fontSize: '12px' }}>*) Jika File lebih dari 1. Compress file ke (RAR / ZIP) dang unggah </small> <br />
                  <small className='text-muted' style={{ fontSize: '12px' }}>*) maksimal file: 10mb dengan ekstensi (JPG/GIF/RAR/PNG/ZIP) </small>
                </Form.Group>
              </Col>
            </Row>
            {
              !loading
                ?
                <Button type='submit' className='my-3'><FaPaperPlane /> Kirim Pengaduan</Button>
                :
                <div className='d-flex align-items-center my-3'>
                  <PuffLoader size={24} className='me-1' />
                  <span>Mohon tunggu...</span>
                </div>
            }
          </Form>
        </Col>
      </Row>
      <Row>
        <ModalTiket backdrop="static" show={modal_show} onHide={() => { return window.confirm('Ingin meninggalkan halaman ini ?') ? set_modal_show(false) : false }} centered size="md"
          aria-labelledby="contained-modal-title-vcenter" />
      </Row>
    </main>
  )
}

export default Pengaduan