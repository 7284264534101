import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import { HostContext, AuthContext } from './context';
import { AuthInit, AuthReducers } from './reducers';
import { NotifContent } from './notify';
const root = ReactDOM.createRoot(document.getElementById('root'));

// const HOST = "http://localhost:8081";
const HOST = "/webservices/index.php";

const InitApp = () => {
  const [STATE_AUTH, DISPATCH_AUTH] = React.useReducer(AuthReducers, AuthInit)
  return (
    <HostContext.Provider value={{ HOST }} >
      <AuthContext.Provider value={{ STATE_AUTH, DISPATCH_AUTH }}>
        <App />
        <NotifContent />
      </AuthContext.Provider>
    </HostContext.Provider>
  )
}
root.render(
  <Router>
    <InitApp />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
